const mapLocations = [
    {
        "S. No": 2,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo- Bharthal (Chai Point)",
        "FC Address": "G/F Plot at Khara No. 233,Village Bharthal,Near Pole No.MJF090,New Delhi -110077",
        "FC Name_Website": "Bharthal, Delhi",
        "FC Address_Website": "Village Bharthal,Near Pole No.MJF090,New Delhi -110077",
        "FC coordinates/location link": "Click here",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "North",
        "Website Sequence": 1,
        "Webiste Remarks_PG": "OK",
        "Kiran to work on?": "",
        "Current Status - ET": "Not publicly visible - Access Denied to Ekesh",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/8e3iRen5HFZqkUeq8",
        "Comments By ET": "To be added and verified by FC head"
    },
    {
        "S. No": 3,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo GGN-Sultanpur",
        "FC Address": "Khasra No. 174/5, 175/1, 2/1/1, 2/1/2, 2/2/1, 2/2/2, 3/1, 3/2, 4/1/1, 4/1/2/, 8, 9, 10, 11/1, Village Sultanpur Tehsil Farukhnagar, Distt. Gurgaon -122506",
        "FC Name_Website": "Sultanpur, Farukhanagar 1, Haryana",
        "FC Address_Website": "Village Sultanpur Tehsil Farukhnagar, Distt. Gurgaon -122506",
        "FC coordinates/location link": "Click here",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "North",
        "Website Sequence": 2,
        "Webiste Remarks_PG": "OK",
        "Kiran to work on?": "",
        "Current Status - ET": "Live",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/4Js3oMb1nDtE9k9H7",
        "Comments By ET": "Location Live"
    },
    {
        "S. No": 4,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo GGN-Sultanpur - 2",
        "FC Address": "Khasra No. 174/5, 175/1, 2/1/1, 2/1/2, 2/2/1, 2/2/2, 3/1, 3/2, 4/1/1, 4/1/2/, 8, 9, 10, 11/1, Village Sultanpur Tehsil Farukhnagar, Distt. Gurgaon -122506",
        "FC Name_Website": "Sultanpur, Farukhanagar 2, Haryana",
        "FC Address_Website": "Village Sultanpur Tehsil Farukhnagar, Distt. Gurgaon -122506",
        "FC coordinates/location link": "Click here",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "North",
        "Website Sequence": "New",
        "Webiste Remarks_PG": "Add",
        "Kiran to work on?": "",
        "Current Status - ET": "Live",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/4Js3oMb1nDtE9k9H7",
        "Comments By ET": "Duplicate of above"
    },
    {
        "S. No": 5,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo Farukhnagar (All Cargo)",
        "FC Address": "KhasraNo.7//8/1/2,13/2,8/2/2,9/2,10/2,11,12,13/1,20,19/2,19/1,18,2\n1,22.23,14//1/1,8//6/2,7/2,8/2,13,14,15,16,17,18,19,24,25,13//4,5,,6,\n7,12/2,12/3,13/1,14,15/1,19 min,22/2/1 min & 19//2/2/2// of Village-\nKhalikpur, Tehsil-Badli, Dist. Jhajjar,Haryana, Pin code - 124105",
        "FC Name_Website": "All Cargo Logistics Park, Jhajjar, Haryana",
        "FC Address_Website": "All Cargo Logistics Park, Village-Khalikpur, Tehsil-Badli, Dist. Jhajjar,Haryana, Pin code - 124105",
        "FC coordinates/location link": "Click here",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "North",
        "Website Sequence": 3,
        "Webiste Remarks_PG": "Ok",
        "Kiran to work on?": "",
        "Current Status - ET": "Listing Live But Not Created By Ekesh",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/AaZZNMzBEsnagdLP9",
        "Comments By ET": "Location Live. Not added by Ekesh"
    },
    {
        "S. No": 6,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo BLR Hoskote 1",
        "FC Address": "Hoskote, Sy No-131/3, Near Marie Gold Logistics ,Anjaneya Garden Ioc Road , Makanahalli Village 560067",
        "FC Name_Website": "Hoskote 1, Bangalore",
        "FC Address_Website": "Hoskote, Anjaneya Garden Ioc Road , Makanahalli Village 560067",
        "FC coordinates/location link": "Click here",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "South",
        "Website Sequence": 6,
        "Webiste Remarks_PG": "OK",
        "Kiran to work on?": "",
        "Current Status - ET": "Live but not verified",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/NTugDLFbWESu5AJg9",
        "Comments By ET": "Location Live but not verified."
    },
    {
        "S. No": 7,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo BLR Hoskote 2",
        "FC Address": "Hoskote, Sy No-131/3, Near Marie Gold Logistics ,Anjaneya Garden Ioc Road , Makanahalli Village 560067",
        "FC Name_Website": "Hoskote 2, Bangalore",
        "FC Address_Website": "Hoskote, Anjaneya Garden Ioc Road , Makanahalli Village 560067",
        "FC coordinates/location link": "Click here",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "South",
        "Website Sequence": 10,
        "Webiste Remarks_PG": "OK",
        "Kiran to work on?": "",
        "Current Status - ET": "Live but not verified",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/NTugDLFbWESu5AJg9",
        "Comments By ET": "Duplicate of above"
    },
    {
        "S. No": 8,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo BLR Hoskote 3",
        "FC Address": "Hoskote, Sy No- 96/1,97/4,97/1 and 105/5 and property number - 183/123, 184/129,185/130 and 181/125, Anjaneya Garden Ioc Road , Makanahalli Village 560067",
        "FC Name_Website": "Hoskote 3, Bangalore",
        "FC Address_Website": "Hoskote, Anjaneya Garden Ioc Road , Makanahalli Village 560067",
        "FC coordinates/location link": "Click here",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "South",
        "Website Sequence": 8,
        "Webiste Remarks_PG": "OK",
        "Kiran to work on?": "",
        "Current Status - ET": "Duplicate",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/fmx9DDMbcuSqVHej9",
        "Comments By ET": "Duplicate of above"
    },
    {
        "S. No": 9,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo BLR Neelmangla",
        "FC Address": "20/2B, Thonachikuppe village, Nelamangala Taluk, Bangalore Rural 562123,",
        "FC Name_Website": "Neelmangla, Bangalore",
        "FC Address_Website": "Thonachikuppe village, Nelamangala Taluk, Bangalore Rural 562123",
        "FC coordinates/location link": "Click here",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "South",
        "Website Sequence": 11,
        "Webiste Remarks_PG": "OK",
        "Kiran to work on?": "",
        "Current Status - ET": "Listing Live But Not Created By Ekesh",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/sua7wypKro6LZqD96",
        "Comments By ET": "Location Live. Not added by Ekesh"
    },
    {
        "S. No": 10,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo Bhiwandi - Amne - Infinity",
        "FC Address": "Gala No 1 to 6, (House No 418/1, 418/2 ,418/3 ,418/4 , 418/5 & 418/6 )\n Kalyan - Padgha Road at village Aamne, Taluka- bhiwandi, Thane - 421302",
        "FC Name_Website": "Aamne, Bhiwandi, Maharashtra",
        "FC Address_Website": "Kalyan - Padgha Road at village Aamne, Taluka- bhiwandi, Thane - 421302",
        "FC coordinates/location link": "Click here",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "West",
        "Website Sequence": 12,
        "Webiste Remarks_PG": "OK",
        "Kiran to work on?": "",
        "Current Status - ET": "Listing Live But Not Created By Ekesh",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/pVv9jRaHk5En9y7n9",
        "Comments By ET": "Location Live. Not added by Ekesh"
    },
    {
        "S. No": 11,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo Bhiwandi - Bawale - Sairaj",
        "FC Address": "H NO:B-145/1,2, Building 'B' Survey No. 39/3/A and 39/3/C and 1/3/5, Sairaj Logistics Hub, Opposite All Saints High School, Savad Naka Road, Bawale Village, Bhiwandi, Maharashtra, 421302.",
        "FC Name_Website": "Sairaj, Bhiwandi, Maharashtra",
        "FC Address_Website": "Sairaj Logistics Hub, Savad Naka Road, Bawale Village, Bhiwandi, Maharashtra, 421302",
        "FC coordinates/location link": "Click here",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "West",
        "Website Sequence": 13,
        "Webiste Remarks_PG": "OK",
        "Kiran to work on?": "",
        "Current Status - ET": "Listing Live But Not Created By Ekesh",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/XwTRQpjckTHv8Dgi8",
        "Comments By ET": "Location Live. Not added by Ekesh"
    },
    {
        "S. No": 14,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo Hyderabad Nacharam 2",
        "FC Address": "Plot no A-36, IDA, Nacharam, Hyderabad, telangana, 500076",
        "FC Name_Website": "Nacharam, Hyderabad",
        "FC Address_Website": "Nacharam, Hyderabad, telangana, 500076",
        "FC coordinates/location link": "Click here",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "South Central",
        "Website Sequence": 17,
        "Webiste Remarks_PG": "OK",
        "Kiran to work on?": "",
        "Current Status - ET": "Live",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/pCa9QhV6oRbau2H9A",
        "Comments By ET": "Location Live"
    },
    {
        "S. No": 16,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo Narketpalli (Reliance)",
        "FC Address": "Warehouse No 2 Ground Floor Village Narketpally Mandal & Nalgonda Building No 8 111/1 Yellareddygudem NARKETPALLE Telangana - 508254",
        "FC Name_Website": "Narketpalli, Telangana",
        "FC Address_Website": "Narketpally Mandal & Nalgonda Building Yellareddygudem NARKETPALLE Telangana - 508254",
        "FC coordinates/location link": "Click here",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "South Central",
        "Website Sequence": 21,
        "Webiste Remarks_PG": "OK",
        "Kiran to work on?": "",
        "Current Status - ET": "Temporarily Closed",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/cNN423XcWYgCtCZ3A",
        "Comments By ET": "Temporarily Closed. Suggested Edit as Open."
    },
    {
        "S. No": 17,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo Reliance PBG - B1",
        "FC Address": "B1, Svy No - 29 & 30, Manoharabad Village & Mandal, Medak District, Telangana - 502336",
        "FC Name_Website": "Manoharabad PBG-1, Telangana",
        "FC Address_Website": "Manoharabad Village & Mandal, Medak District, Telangana - 502336",
        "FC coordinates/location link": "Click here",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "South Central",
        "Website Sequence": 22,
        "Webiste Remarks_PG": "OK",
        "Kiran to work on?": "",
        "Current Status - ET": "Live",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/yEzC95yjA6jErJSX7",
        "Comments By ET": "Location Live"
    },
    {
        "S. No": 20,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo Badhpura - Dadri",
        "FC Address": "Khasra no - 310 - 311, NH - 91, Dadri bypass, Village - Badhpura, Dadri,  Gautam Buddh Nagar, 203207.",
        "FC Name_Website": "Badhpura - Dadri, UP",
        "FC Address_Website": "Village - Badhpura, Dadri,  Gautam Buddh Nagar, 203207.",
        "FC coordinates/location link": "Click here",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "North",
        "Website Sequence": 23,
        "Webiste Remarks_PG": "OK",
        "Kiran to work on?": "",
        "Current Status - ET": "Live",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/keZnS9vcHeTqkpg67",
        "Comments By ET": "Location Live"
    },
    {
        "S. No": 21,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo Kolkata Prospace",
        "FC Address": "Prospace Logistics Park, Choto Balu Post, Belumilki, Serampore, Milki \nBadamtola, WB - 712223",
        "FC Name_Website": "Prospace Logistics Park, Kolkata, WB",
        "FC Address_Website": "Prospace Logistics Park, Milki Badamtola, WB - 712223",
        "FC coordinates/location link": "Click here",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "East",
        "Website Sequence": 24,
        "Webiste Remarks_PG": "OK",
        "Kiran to work on?": "",
        "Current Status - ET": "Listing Live But Not Created By Ekesh",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/cyQWdTuNKwUTJWTA7",
        "Comments By ET": "Location Live. Not added by Ekesh"
    },
    {
        "S. No": 22,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo Reliance PBG - B2",
        "FC Address": "B2, Svy No - 29 & 30, Manoharabad Village & Mandal, Medak District, Telangana - 502336",
        "FC Name_Website": "Manoharabad PBG-2, Telangana",
        "FC Address_Website": "Manoharabad Village & Mandal, Medak District, Telangana - 502336",
        "FC coordinates/location link": "Click here",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "South Central",
        "Website Sequence": 26,
        "Webiste Remarks_PG": "OK",
        "Kiran to work on?": "",
        "Current Status - ET": "Live",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/gKScwpJAcKWkEru8A",
        "Comments By ET": "Location Live. Not added by Ekesh"
    },
    {
        "S. No": 23,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo Cold WH Vizag",
        "FC Address": "Sri Mitra Marine Agencies, SY.NO. 63-6p, 63-7p, 63-8p, 63-19p, Opposite NSRIT collegue, Sontyam Village, Anandapuram Mandal, Vishakahapatnam, Andhra Pradesh - 531173.",
        "FC Name_Website": "Cold WH Vizag",
        "FC Address_Website": "Sri Mitra Marine Agencies, Anandapuram Mandal, Vishakahapatnam, Andhra Pradesh - 531173.",
        "FC coordinates/location link": "Click Here",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "South Central",
        "Website Sequence": 27,
        "Webiste Remarks_PG": "OK",
        "Kiran to work on?": "",
        "Current Status - ET": "Not Listed",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/jFAtGwoZvmLuNaJU7",
        "Comments By ET": "To be added and verified by FC head"
    },
    {
        "S. No": 25,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo Lucknow",
        "FC Address": "B 655/01,Khasra No.184, Village Khasarwara, Lucknow, Uttar Pradesh. Pin Code - 226401",
        "FC Name_Website": "Lucknow, UP",
        "FC Address_Website": "Village Khasarwara, Lucknow, Uttar Pradesh. Pin Code - 226401",
        "FC coordinates/location link": "Click Here",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "North",
        "Website Sequence": 31,
        "Webiste Remarks_PG": "OK",
        "Kiran to work on?": "",
        "Current Status - ET": "Live",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/HMSCM9quxnjw1T389",
        "Comments By ET": "Location Live. Not added by Ekesh"
    },
    {
        "S. No": 26,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Manoharabad (LEAP)",
        "FC Address": "Warehouse No. A-2, Shri Rajlaxmi Logistics Park, Survey No.29, Manoharbad village & mandal, Medak District, Telangana, Hyderabad, Pin code - 502336",
        "FC Name_Website": "Shri Rajlaxmi Logistics Park, Telangana",
        "FC Address_Website": "Shri Rajlaxmi Logistics Park, Medak District, Telangana, Hyderabad, Pin code - 502336",
        "FC coordinates/location link": "Click here",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "South Central",
        "Website Sequence": "New",
        "Webiste Remarks_PG": "Add",
        "Kiran to work on?": "",
        "Current Status - ET": "Live",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/zSt5bTVG4uwQhafz9",
        "Comments By ET": "Location Live. Not added by Ekesh"
    },
    {
        "S. No": 27,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Farukh Nagar, Khera Khurampur",
        "FC Address": "Khasra No. 101//2/1/2,3/2/1,8/2 & 9/1 Village Farukh Nagar,Farukh Nagar Distt,Gurugram, Haryana 122506",
        "FC Name_Website": "Farukhanagar 2, Haryana",
        "FC Address_Website": "Village Farukh Nagar,Farukh Nagar Distt,Gurugram, Haryana 122506",
        "FC coordinates/location link": "Link",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "North",
        "Website Sequence": 29,
        "Webiste Remarks_PG": "OK",
        "Kiran to work on?": "",
        "Current Status - ET": "Not Listed",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/z4QDXVN8mqsT8UPA9",
        "Comments By ET": "To be added and verified by FC head"
    },
    {
        "S. No": 28,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo Chennai (DCB) Redhills",
        "FC Address": "Warehouse No. 3 and 4 at DCB Group Warehouse at Sothupakkam, situated at Sothupakkam Road, Sothupakkam Village, Redhills, Chennai 600 052",
        "FC Name_Website": "Chennai, Tamil Nadu",
        "FC Address_Website": "DCB Group Warehouse, Sothupakkam Village, Redhills, Chennai 600 052",
        "FC coordinates/location link": "Click here",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "South",
        "Website Sequence": 15,
        "Webiste Remarks_PG": "OK",
        "Kiran to work on?": "",
        "Current Status - ET": "Not Listed",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/uSsRQJHvzW3myZdA7",
        "Comments By ET": "To be added and verified by FC head"
    },
    {
        "S. No": 29,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo Taru-Bilaspur Road (Reliance UrbanLadder)",
        "FC Address": "Outrank Logistics Park - Khasra No. 24//17,18,23,24,25,29//3,4,5/1,5/2,6,30//1,9/2,10,11,12,13/1,14/1,15/1 min,19,20/1,22/1\nVillage- Tauru, Tehsil-Tauru, District- Nuh, Haryana",
        "FC Name_Website": "Taru-Bilaspur Road, Haryana",
        "FC Address_Website": "Outrank Logistics Park,Village- Tauru, Tehsil-Tauru, District- Nuh, Haryana",
        "FC coordinates/location link": "Click here",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "North",
        "Website Sequence": 4,
        "Webiste Remarks_PG": "OK",
        "Kiran to work on?": "",
        "Current Status - ET": "Not publicly visible - Access Denied to Ekesh",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/uW7vAyM6zEX3Tf3Q8",
        "Comments By ET": "To be added and verified by FC head"
    },
    {
        "S. No": 30,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo Ludhiana",
        "FC Address": "Plot No. 117, Lal bagh, Near Raj Guru Nagar, Ferozpur Road, Ludhiana, 141012.",
        "FC Name_Website": "Ludhiana, Punjab",
        "FC Address_Website": "Lal bagh, Near Raj Guru Nagar, Ferozpur Road, Ludhiana, 141012.",
        "FC coordinates/location link": "Click Link",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "North",
        "Website Sequence": 5,
        "Webiste Remarks_PG": "OK",
        "Kiran to work on?": "Add google location or google coordinates",
        "Current Status - ET": "NA",
        "Live Listing Link/Location - ET": "https://maps.google.com/maps?q=30.8806244%2C75.7809861&z=17&hl=en",
        "Comments By ET": "To be added and verified by FC head"
    },
    {
        "S. No": 31,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo Bidadi",
        "FC Address": "Plot no. 34D, Bidadi Industrial area, Opposite to Toyota Gate no. 5,Shynamangala Village, 1 st Phase, Survey no. 54, Bidadi Hobli, Ramanagara,District,",
        "FC Name_Website": "Bidadi, Karnataka",
        "FC Address_Website": "Bidadi Industrial area, Shynamangala Village, Bidadi Hobli, Ramanagara,District,",
        "FC coordinates/location link": "Click Link",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "South Central",
        "Website Sequence": "New",
        "Webiste Remarks_PG": "Add",
        "Kiran to work on?": "Add google location or google coordinates",
        "Current Status - ET": "NA",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/7winDXXzTHkaA8CX9",
        "Comments By ET": "To be added and verified by FC head"
    },
    {
        "S. No": 32,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo Makali",
        "FC Address": "Survey No. 64, Harokyathanahalli Village, Makali Post, Dasanapura Hobli, Bangalore, 562123",
        "FC Name_Website": "Makali, Karnataka",
        "FC Address_Website": "Harokyathanahalli Village, Makali Post, Dasanapura Hobli, Bangalore, 562123",
        "FC coordinates/location link": "Click Link",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "South",
        "Website Sequence": "New",
        "Webiste Remarks_PG": "Add",
        "Kiran to work on?": "Add google location or google coordinates",
        "Current Status - ET": "NA",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/6y2iqmbJLwNSEssC7",
        "Comments By ET": "To be added and verified by FC head"
    },
    {
        "S. No": 33,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo Ozone Bhiwandi",
        "FC Address": "Survey No. 1/2/1/A,14/13A,House No. 148, Unit No. 10,11 & 13 ‘D’ Building, RK Infra Complex, Village Janwal, Bhiwandi, Mumbai, Maharashtra - 421302",
        "FC Name_Website": "RK Infra Complex, Bhiwandi",
        "FC Address_Website": "RK Infra Complex, Village Janwal, Bhiwandi, Mumbai, Maharashtra - 421302",
        "FC coordinates/location link": "19.309441, 73.140108",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "West",
        "Website Sequence": 19,
        "Webiste Remarks_PG": "OK",
        "Kiran to work on?": "",
        "Current Status - ET": "NA",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/YCAUuLYUDAn8d3mS6",
        "Comments By ET": "To be added and verified by FC head"
    },
    {
        "S. No": 34,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo Cold WH Krishnapatnam",
        "FC Address": "IG International Private Limited, Survey No 455, Epuru Lb Village, Muthikur Mandal, Krishnapatnam-524323",
        "FC Name_Website": "Cold WH Krishnapatnam",
        "FC Address_Website": "IG International Private Limited, Muthikur Mandal, Krishnapatnam-524323",
        "FC coordinates/location link": "CLICK LINK",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "South Central",
        "Website Sequence": 18,
        "Webiste Remarks_PG": "OK",
        "Kiran to work on?": "Add google location or google coordinates",
        "Current Status - ET": "NA",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/A9fchEKcNY5Rfdfo9",
        "Comments By ET": "To be added and verified by FC head"
    },
    {
        "S. No": 35,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo Chennai (Indospace)",
        "FC Address": "Unit No - B-200B,  building No. B200 (DTCP approved building no. B-200), Indospace Industrial & Logistics Park Kunnam, Survey Nos. 229/1B (Part), 229/2A, 229/2B, 229/2C (Part), 229/2D (Part), 230/2 (Part), 231/1 (Part) and 231/2 (Part),  SRIPERUMBUDUR TALUK, KUNNAM VILLAGE, Kanchipuram, Tamil Nadu, 631604",
        "FC Name_Website": "Indospace Industrial & Logistics Park, Chennai",
        "FC Address_Website": "Indospace Industrial & Logistics Park Kunnam, Kunnam Village, Kanchipuram, Tamil Nadu, 631604",
        "FC coordinates/location link": "Link",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "South",
        "Website Sequence": 28,
        "Webiste Remarks_PG": "OK",
        "Kiran to work on?": "",
        "Current Status - ET": "Not Listed",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/nhTz4EYS2r44suKb7",
        "Comments By ET": "To be added and verified by FC head"
    },
    {
        "S. No": 36,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo Medchal, Telangana",
        "FC Address": "Railway Colony Road, near Durga Matha Temple, Medchal, Secunderabad, Telangana 501401\nLandmark: Opp. Medchal Railway Station",
        "FC Name_Website": "Medchal, Telangana",
        "FC Address_Website": "Medchal, Secunderabad, Telangana 501401",
        "FC coordinates/location link": "Link",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "South Central",
        "Website Sequence": 20,
        "Webiste Remarks_PG": "OK",
        "Kiran to work on?": "",
        "Current Status - ET": "Not Listed",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/AZnzyeg98LmUEeks9",
        "Comments By ET": "To be added and verified by FC head"
    },
    {
        "S. No": 37,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo Soukya Road (Reliance)",
        "FC Address": "Survey no.37 &38, Katha N 143/106, Janjar-N91, Karcharakanahalli, Jadigenahalli Soukya Road, Hoskote taluk, Bendaluru - 562114",
        "FC Name_Website": "Soukya Road, Bangalore",
        "FC Address_Website": "Karcharakanahalli, Jadigenahalli Soukya Road, Hoskote taluk, Bendaluru - 562114",
        "FC coordinates/location link": "Link",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "South",
        "Website Sequence": 9,
        "Webiste Remarks_PG": "OK",
        "Kiran to work on?": "",
        "Current Status - ET": "Not Listed",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/aLqXuKuz84X23zjp8",
        "Comments By ET": "To be added and verified by FC head"
    },
    {
        "S. No": 38,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo Bhiwandi Millennium Logistic Park",
        "FC Address": "Survey No : 68/3, 68/4, 68/8, 69,/10 Shed No. C1 MILLENNIUM LOGISTIC PARK, SAWAD NAKA KALYAN PADGHA ROAD SAWAD BHIWANDI - 421302",
        "FC Name_Website": "Millennium, Bhiwandi, Mumbai",
        "FC Address_Website": "Millennium Logistic Park, Sawad Bhiwandi - 421302",
        "FC coordinates/location link": "19.3043676 73.1539142",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "West",
        "Website Sequence": 14,
        "Webiste Remarks_PG": "OK",
        "Kiran to work on?": "",
        "Current Status - ET": "Not publicly visible - Access Denied to Ekesh",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/y74o8y14KqRWsEs4A?g_st=com.google.maps.preview.copy",
        "Comments By ET": "To be added and verified by FC head"
    },
    {
        "S. No": 44,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo Kolkata02",
        "FC Address": "Global Logistics Park, Warehouse No. 1, Old Delhi Road,Seorampore,Hooghly, Near Bhushan Steel Factory,West Bengal – 712203",
        "FC Name_Website": "Global Logistics Park, Kolkata, WB",
        "FC Address_Website": "Global Logistics Park, Old Delhi Road,Seorampore,  West Bengal – 712203",
        "FC coordinates/location link": "Click here",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "East",
        "Website Sequence": 25,
        "Webiste Remarks_PG": "OK",
        "Kiran to work on?": "Done",
        "Current Status - ET": "Not Listed",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/W9QrfuRzpmKaYVcc6",
        "Comments By ET": "To be added and verified by FC head"
    },
    {
        "S. No": 45,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo Securadarabad UP",
        "FC Address": "VRY Industrial Park LLP, Gulaothi Road, Sikandrabad, Bulandshahar, Uttar Pradesh, PIN 203205",
        "FC Name_Website": "Sikandrabad, UP",
        "FC Address_Website": "VRY Industrial Park LLP, Sikandrabad, Uttar Pradesh, PIN 203205",
        "FC coordinates/location link": "Click here",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "Central",
        "Website Sequence": "",
        "Webiste Remarks_PG": "Add",
        "Kiran to work on?": "Done",
        "Current Status - ET": "Not Listed",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/PbYxm85mjhcChhN16",
        "Comments By ET": "To be added and verified by FC head"
    },
    {
        "S. No": 46,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo Neemans_HYD",
        "FC Address": "Survey\nNo. 42/E2, TIN SHED 6000 sq ft., Shamshabad Village and Mandal, Next to Hyderabad\nIndustries Oil Depot Lane, Manjiyar Farms, Ranga Reddy Dist., Telangana State – Pin code –\n501218",
        "FC Name_Website": "Mandal, Telangana",
        "FC Address_Website": "Shamshabad Village and Mandal, Manjiyar Farms, Telangana State – Pin code –\n501218",
        "FC coordinates/location link": "Click here",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "South Central",
        "Website Sequence": "",
        "Webiste Remarks_PG": "Add",
        "Kiran to work on?": "Done",
        "Current Status - ET": "Not Listed",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/LDAVaHabMdguY3346",
        "Comments By ET": "To be added and verified by FC head"
    },
    {
        "S. No": 47,
        "Company Name": "Prozo Integrated Logistics Private Limited",
        "FC Name": "Prozo BLR Kreedo",
        "FC Address": "120/3 Old Sy No 120/2 Bheemanakuppe Village, Kengeri hobli, Bangalore south Taluk, Bangalore 560074",
        "FC Name_Website": "Hobli, Bangalore",
        "FC Address_Website": "Bheemanakuppe Village, Kengeri hobli, South Taluk, Bangalore 560074",
        "FC coordinates/location link": "Click here",
        "To remove from google? (Yes/No)": "No",
        "To add on google? (Yes/no)": "Yes",
        "Zone": "South",
        "Website Sequence": "",
        "Webiste Remarks_PG": "Add",
        "Kiran to work on?": "Done",
        "Current Status - ET": "Not Listed",
        "Live Listing Link/Location - ET": "https://maps.app.goo.gl/Jgp6PPVeP7Pun8z87",
        "Comments By ET": "To be added and verified by FC head"
    }
]

export default mapLocations;