const testimonials = [
    {
        "name": "Kunal Popat",
        "designation": "Founder",
        "image": "vlmfm.Kunal-poapt-Im.jpg",
        "title": "R for Rabbit is a D2C brand focused on baby\u2019s development and safety to make parenting easy & joyful.",
        "description": [
            "We at R for Rabbit were managing our operations in-house when we chanced upon Prozo. The Prozo team moved very fast and was able to understand our pain-points, especially handling of our large & bulky products. They came up with a pan-India pay-per-use solution for all our warehousing & freight requirements which gave us the flexibility to expand our footprint to newer geographies.",
            "We started with warehousing & freight operations from one location. Having seen the team's understanding of our operations, their tech capabilities and seamless handling of all our queries, we decided to expand our business with them to newer geographies. Prozo\u2019s in-house warehousing, freight and tech-stack has ensured we have 100% visibility on our operations.",
            "Prozo is now our pan-India, multi-channel supply chain partner and we look forward to expanding to newer geographies with them!"
        ],
        "website": "https://rforrabbit.com/",
        "brand": "R for Rabbit"
    },
    {
        "name": "Sourabh Jain",
        "designation": "Director Supply Chain Management",
        "image": "Sourabh_Jain-removebg-preview.png",
        "title": "Chai Point is an omni-channel beverage brand that brings a perfectly brewed cup of chai that is made with fresh and natural ingredients.",
        "description": [
            "We are impressed by how responsive Prozo\u2019s team is to promptly respond to our queries and fulfill all urgent requests. With Prozo, we have been able to ensure best in class service to our stores and partners. They have a rigorous project management process, ensuring all SOPs are followed to the best-in-class SLAs.",
            "Prozo is serving us for B2B warehousing in NCR and we\u2019d want to work with them in more territories going forward. Their central operations team has been quite proactive in ensuring the FC-level SLAs and TATs are adhered to. Overall, we have had a great experience working with Prozo and its team."
        ],
        "website": "https://shop.chaipoint.com/",
        "brand": "Chai Point"
    },
    {
        "name": "Sachin Singhal",
        "designation": "Co-founder & COO",
        "image": "Sachin_Singhal__Co-founder-removebg-preview.png",
        "title": "EkAnek offers 3 products: a) Foxy - India\u2019s first beauty social commerce app for both men & women b) Boho Botanist - d2c bodycare brand and c) DTC Platform -  enabling beauty & grooming brands in setting up their own d2c e-commerce capabilities",
        "description": [
            "Timely delivery within an aggressively competitive ETA in all parts of the country is a must in e-commerce today. Any drop in adherence leads to higher RTO, cancellation and drop in retention. For the supply chain team, it is imperative to work with a partner who is committed to these aggressive targets and agile and flexible to work together to create customer delight.  We moved our supply chain from a traditional 3PL partner to Prozo in 2021 and are delighted to have made this choice early in our scale up journey. Prozo stands out in the market with their tech first approach and are committed bunch of folks who want to win in the market with all their clients.",
            "They have worked with us as a partner in thick of things and won our trust to be our default choice for any warehousing services. Our experience with Prozo has been great. Prozo team has been able to meet and exceed our target SLAs for inwards, outwards and returns. We have found them to be very prompt in helping us solve our customer issues by providing order level packing video feeds, wherever needed. Prozo has recently been entrusted with handling a part of our freight as well. We find Prozo\u2019s on-ground team as well as senior management very responsive to our business needs. I can say we have an extended supply chain team in Prozo!"
        ],
        "website": "https://www.ekanek.io/",
        "brand": "EkAnek"
    },
    {
        "name": "Suhas Dsouza",
        "designation": "Lead - Supply Chain",
        "image": "Suhas_D_souza-removebg-preview.png",
        "title": "Traya is India\u2019s first holistic hair loss solution startup that has shown hair regrowth to more than 2 Lakh Indians. Our all round treatment plan is a combination of 3 sciences - Dermatology, Ayurveda & Nutrition!",
        "description": [
            "At Traya,  every order requires a doctor\u2019s prescription before it can be shipped, therefore making fast-fulfilment a unique problem. Keeping this in mind, we wanted a highly tech-enabled warehousing & fulfilment partner who could meet our warehousing needs and technology integration requirements. The Prozo team eagerly & systematically integrated with our CRM to ensure that orders and prescriptions are pulled real-time into Prozo OMS & WMS to fulfill the orders within strictly defined TATs.",
            "We have had an enriching experience with Prozo so far. The team has been able to handle demand fluctuations very well and customer queries have been resolved in a timely manner. All our inwards and return processes have been taken care of as per SoP. Overall, they have a delighted customer in Traya!"
        ],
        "website": "https://traya.health/",
        "brand": "Traya"
    },
    {
        "name": "Heena Pawar",
        "designation": "Business Head",
        "image": "we-removebg-preview (1).png",
        "title": "Powerlook: Omni-channel brands for classy men\u2019s streetwear!",
        "description": [
            "We started working with Prozo in 2022. Earlier, our supply chain was being managed in-house. With growing business and increasing supply chain complexity, we were looking for a trust-worthy warehousing partner which could take care of our D2C warehousing & fulfilment to begin with. Order fulfilment TATs are extremely critical for our customers and we wanted a strong partner who could handle D2C warehousing and tech-enable the warehousing & fulfilment part.",
            "Since we were outsourcing for the first time, as a part of our evaluation process, we visited potential 3PL partners' warehouses, took detailed demonstrations of their WMS and OMS, and also took references from their existing clients. We found Prozo to be quite unique since they were the only partner who scored very high on quality of warehouses & warehousing infrastructure, end-to-end technology stack, quality to engagement at every stage of the process and received extremely good feedback from their existing customers.",
            "Prozo is currently managing our entire D2C fulfilment from Bhiwandi and is our OMS & WMS partner. We look forward to deepening our partnership with Prozo!"
        ],
        "website": "https://www.powerlook.in/",
        "brand": "Powerlook"
    },
    {
        "name": "Amlan Mukherjee",
        "designation": "Chief Supply Chain Officer",
        "image": "Amlan_Mukherjee_-removebg-preview.png",
        "title": "VAHDAM\u00ae India is an award-winning, sustainable & ethical global wellness brand taking the best of Indian wellness to the world under a homegrown brand.",
        "description": [
            "We were looking for an on-demand warehousing partner when we came across Prozo. A clean & well-managed warehouse is very important for us since we are into food products. We found Prozo\u2019s warehouses to be fully compliant when we visited them and were able to quickly close on pay-per-use commercials, keeping in view our flexible requirement of the space. Our onboarding was seamless and operations have been flawless. Even as an on-demand warehousing partner, Prozo manages our operations using their in-house WMS and therefore, we have a real-time view into our operational metrics. We look forward to growing our partnership with Prozo as and when the opportunity arises."
        ],
        "website": "https://www.vahdam.in/",
        "brand": "VAHDAM\u00ae India"
    },
    {
        "name": "Neehar Modi",
        "designation": "Co-Founder",
        "image": "Neehar_Modi-removebg-preview (1).png",
        "title": "Hyugalife (Pratech Brands) is building India\u2019s largest digital first health and wellness retail platform!",
        "description": [
            "As a health & wellness focused platform, customer experience is of paramount importance for us at Hyugalife. We work with more than 300 brands and a large variety of SKUs. We were looking for a strong warehousing & fulfilment partner with capabilities that would allow us to be agile at our early stages and grow with us as we scale. For us - quality check, packaging and storage guidelines vary depending on the product category and are very important aspects of warehousing operations. Prozo carries deep capabilities in all our areas of importance.",
            "After a thorough evaluation, we chose Prozo as a partner looking at Prozo\u2019s existing experience of serving various other platform customers like Snapdeal,",
            "Bharat Agri, Ek Anek, WH Smith and their in-house technology stack for all supply chain requirements, be it warehousing, freight or technology. The fact that they govern their warehouse operations themselves and have a strong technology framework in the form of command center and control tower made Prozo our obvious choice."
        ],
        "website": "https://hyugalife.com/",
        "brand": "Hyugalife"
    },
    {
        "name": "Paras Anand, Director",
        "designation": "Marketing & Sales",
        "image": "1657047527004-removebg-preview-removebg-preview.png",
        "title": "Sanspareils Greenlands (SG) is the world's largest and most trusted cricket equipment company. The company was established in pre-independent India in 1931 by two brothers Kedar Nath Anand & Dwarka Nath Anand. In the 70\u2019s, Sunil Gavaskar became SG\u2019s brand ambassador, and the rest is history as they say!",
        "description": [
            "Prozo has been serving SG as our supply chain partner for the past 6 months. Prozo seamlessly provided tech integrations that helped us extend our B2C/D2C operations. They offer all the major marketplace connectivity at one place, we added Amazon, Flipkart, Ajio, Shopify, Woocomerce, Tatacliq, Myntra etc.. Prozo team is just a call away, on their feet to cater to our demands, be it order volumes or custom feature development. With Prozo, we have doubled our B2C and D2C order processing in a very short span of time. At SG, we are now planning to add Prozo in our B2B business as well and planning to integrate our ERP with Prozo\u2019s tech in order to reduce manual-to-fro communication. Thanks Prozo, we look to continue this partnership and grow together!"
        ],
        "website": "https://shop.teamsg.in/",
        "brand": "Sanspareils Greenlands (SG)"
    },
    {
        "name": "Sai Gole",
        "designation": "Co-Founder",
        "image": "Sai_Gole-removebg-preview.png",
        "title": "Bharti Agri is an agri-tech platform helping farmers increase their yield and income by offering farming insights and products!",
        "description": [
            "Bharat Agri\u2019s customer is the farmer, located in far-flung areas of the country. Therefore, we need a supply chain partner who is deeply committed to our mission to serve these farmers. Keeping in view the unique & challenging nature of our supply chain requirements, we wanted an end-to-end partner who could manage not only our warehousing as well as freight, but also someone who can integrate with our custom-made technology  platform.",
            "We had evaluated multiple new-age supply chain companies, but Prozo\u2019s full stack approach on a pay-per-use model stood out. With its superior tech stack, Prozo was able to offer customizations to meet our business needs. Managing high delivery rates to our farmers is a key focus for us and Prozo has been extremely committed to co-create solutions to maximize delivery rates to our customers. As we grow our business, we look forward to leverage Prozo\u2019s capabilities to launch a marketplace to further serve our farmers with even larger aggregation of products"
        ],
        "website": "https://www.bharatagri.com/",
        "brand": "Bharti Agri"
    },
    {
        "name": "Dimple Kodwani",
        "designation": "Manager - Forecast & Planning",
        "image": "dimple.png",
        "title": "LEAP is a leader in providing pallets and foldable large containers (FLC\u2019s), with a 70% market share. Their products and customized supply chain solutions help companies build efficiencies in the supply chain management workflow.",
        "description": [
            "We were looking for a robust supply chain partner who was fully compliant,could manage operations end to end along with providing value added services like handling repairs of spare parts. Also, on-time order processing and adherence to TATs are very critical in our business and were an important decision making criteria for us.",
            "Prozo\u2019s team moved very fast during the RFQ process and were very responsive to our requests. They quickly understood the SOP and were able to lock the facility, operationalize it and stabilize operations in a short period of time. Besides this, their strong project management abilities, along with a strong regional operations team ensured minimum escalations during the transition phase. We look forward to growing our geographic footprint with Prozo."
        ],
        "website": "https://leapindia.net/",
        "brand": "LEAP"
    },
    {
        "name": "Mukul Jain",
        "designation": "CFO",
        "image": "WhatsApp_Image_2022-12-30_at_21.03.09-removebg-preview.png",
        "title": "WH Smith, headquartered in Swindon, UK is a leading chain of convenience retail  stores with 1,200 outlets worldwide.",
        "description": [
            "From a legacy 3PL company, we wanted to move to a tech enabled, agile supply chain partner and decided to partner with Prozo.",
            "Prozo started serving us with a single warehouse in Delhi NCR. Impressed by the team\u2019s operations management and end-to-end, multi-category tech-stack which is capable of handling shelf-life based fulfilment besides FEFO based picking methodology, we entrusted them with our mother hub operations along with last-mile delivery to our retail stores. The team has been able to provide best-in-class services for both ambient and temperature-controlled products for us.",
            "Strong processes, superior tech-stack, adherence to SLAs and a client-first mindset truly makes Prozo stand out. We look forward to deepen our partnership with Prozo in the time to come!"
        ],
        "website": "https://www.whsmithindia.com/",
        "brand": "WH Smith (Travel News Services India Pvt Ltd)"
    },
    {
        "name": "Seerat Gupta",
        "designation": "Head of Operations",
        "image": "Seerat Gupta, Head of Operations BeatXP.jpg",
        "title": "beatXP one of India's top fit tech brand leading to serve 10 million customers",
        "description": [
            "We entrusted Prozo with our B2B Warehousing operations for a specific region. Strong performance management along with seamless operations gave us the confidence to entrust them with our pan-India warehousing & freight requirements.",
            "Prozo has been able to offer a single, pay-per-use rate card for all our supply chain needs. We are delighted with the team\u2019s ability to handle complex, multi-channel (both B2B and B2C) operations. Also, they were able to set-up and stabilize new locations for us in record time.",
            "The team is very responsive and has strong processes in place to handle variability in demand, while meeting the committed SLAs. We are thrilled to be working with a player who can seamlessly handle all our supply chain requirements!"
        ],
        "website": "https://beatxp.com/",
        "brand": "beatXP (Pristyn Care)"
    },
    {
        "name": "Dheeraj Madan",
        "designation": "Associate Director, Supply Chain",
        "image": "1579086780085-removebg-preview.png",
        "title": "Snapdeal is India's leading pure-play value Ecommerce platform",
        "description": [
            "Snapdeal chose Prozo as its warehousing partner as a part of its RU (Regional Utilization) improvement program. During our evaluation process, we found Prozo\u2019s capabilities to be best-in-class on all key aspects of the supply chain; be it quality of warehouses, warehousing infrastructure or warehousing operations. Their core focus on delivering better TATs compared to the industry was clear looking at how they serve their clients. The fact that they have a dedicated central operations team to onboard and stabilize their clients and their ability to track & measure real-time operations through command center and control tower was something that gave us a lot of confidence in their capabilities.",
            "We recently started working with Prozo and are extremely happy with our experience so far. The team understood our requirements very well and had an in-depth knowledge of our existing ops and tech. They were quickly able to onboard and stabilize our operations in a short span of time.  We look forward to scaling up our business with a new-age supply chain partner like Prozo."
        ],
        "website": "https://www.snapdeal.com/",
        "brand": "Snapdeal"
    },
    {
        "name": "Mahesh Inder Singh",
        "designation": "VP",
        "image": "Dr-Mahesh.png",
        "title": "",
        "description": [
            "Prozo has been serving as Omni-channel warehousing, technology, and distribution partner for Wolters Kluwer since last 1.5 Yrs+. Prozo has digitised our entire supply chain and delivered the impact that goes beyond cost-saving to revenues-growth and cash flow-optimization."
        ],
        "website": "#",
        "brand": "Health Vertical Wolters Kluwer"
    },
    {
        "name": "Mrs Divya",
        "designation": "Promoters",
        "image": "Divya_-_Oswaal_Books-removebg-preview.png",
        "title": "",
        "description": [
            "Prozo helped us come closer to our customers by helping us deliver an aspirational TAT of 24-48 hours to the majority of our customer in southern India. Prozo team understand what is key to any client and goes beyond the call of duty to deliver what the client and client's customers desire"
        ],
        "website": "#",
        "brand": "Oswaal Books"
    },
    {
        "name": "Dr. Lalit Singh",
        "designation": "Managing Director",
        "image": "lalit.png",
        "title": "",
        "description": [
            "McGraw Hill has been using Prozo's on-demand warehousing & fulfilment services at Noida location since July, 2021. The onboarding process and overall project implementation was pretty smooth with full on-boarding taking less than 4 weeks. The Prozo team is professional and responsive and has helped us deliver seamless experience to our customers during this time."
        ],
        "website": "#",
        "brand": "McgrawHill India"
    }
]

export default testimonials;