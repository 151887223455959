const rtoReduction = [
    {
        title: 'Order Confirmation Services',
        content: [
            'As a part of our order confirmation services, we integrate with ecosystem partners to get you best tools to confirm orders before they are shipped.'
        ]
    },
    {
        title: 'Address Verification Services',
        content: [
            'We assign a address quality score to every shipment once its shipped from our or your warehouse. Shipments with low address scores are handled by our last mile operations team to ensure maximum deliveries. ',
        ]
    },
    {
        title: 'Real-time tracking Services',
        content: [
            'Our free of cost white labelled branded tracking page ensures that your customers can track the shipments directly from your webstores. Additionally our real time updates on WhatsApp and SMS ensure the customers are informed on their shipments.',
        ]
    },
    {
        title: 'NDR-Management Services',
        content: [
            'Our comprehensive NDR management suite comprising of tele calling, WhatsApp automation and rule based automation strives for maximum deliveries for your shipments.',
        ]
    }
];

export default rtoReduction;