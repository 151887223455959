import { Link } from "gatsby";
import React from "react";
import TopMenus from "./TopMenus";
import Video from "./Video";

function Header({ headerClass }) {

    return (
        <header id="topnav" className={headerClass || 'white'}>
            <div className="miniHeder">
                <p>
                    <a href="tel:+919311847248">Call Us at : +91 - 9311847248</a> |{" "}
                    <a href="mailto:3pl.leads@prozo.com">
                        Write to us at : 3pl.leads@prozo.com
                    </a>
                </p>
            </div>
            <div className="container">
                <div>
                    <Link className="logo" to="/">
                        <Video src={"/media/logo.svg"} height={50} alt="" />
                    </Link>
                </div>
                <div className="buy-button">
                    <button
                        type="button"
                        className="btn btn-blue pulse-blue radius"
                        data-toggle="modal"
                        data-target="#contact"
                    >
                        Get Started
                    </button>
                </div>

                <div className="menu-extras">
                    <div className="menu-item">
                        <a className="navbar-toggle">
                            <div className="lines">
                                <span />
                                <span />
                                <span />
                            </div>
                        </a>
                    </div>
                </div>

                <TopMenus />

            </div>
        </header>
    )
}

export default Header;