const caseStudies = [{
    "image": "Regional Fulfillment Excellence for a leading FMCD Client!.jpg",
    "title": "Regional Fulfilment Excellence for a leading FMCD Client!",
    "tags": "B2B, FMCD, ProWMS, Local Transportation, PTL, FTL,  Regional Warehouse, Control Tower, Command Centre",
    "categories": ["FMCD"],
    "description": ["A leading home appliances brand was exploring to move to a professional multi-channel, warehousing & fulfilment company from the current \"CFA-based model for b2b shipments\" and \u2018pan-India b2c fulfilment from a single mother warehouse\u2019.", "The brand was looking to solve the key pain point of \"poor peak-volume handling\", \u201cinconsistent service levels in transportation\u201d besides looking to upgrade to Grade-A, compliant facilities at a similar price point.", "Moreover, the company was looking to entrust their secondary transportation to a professional warehousing & fulfilment partner who could own up to end-to-end TAT and service levels.", "Prozo was chosen as their supply chain partner for North India by looking at Prozo\u2019s warehousing, fulfilment and transportation capabilities."]
}, {
    "image": "Enabling-best-in-class,-OTIF-supply-chain-for-a-world-renowned-high-street-retailer!.png",
    "categories": ["FMCG"],
    "title": "Enabling best-in-class, OTIF supply chain for a world-renowned high-street retailer!",
    "tags": "B2B, FMCG, ProWMS, Local Transportation, Mother Warehouse, Regional Warehouse,  SAP Integration, Control Tower, Command Centre",
    "description": ["A leading high-street retailer was looking to improve fulfilment rate and order shipping timelines for its airport and metro retail stores. Furthermore, this multi-category retailer was looking to tech-enable the supply chain by deploying a multi-category WMS capable of handling shelf-life based fulfilment besides FEFO based picking methodology. As a part of this project, the client required an ambient as well as temperature-control facility for storage of various categories of goods. ", "As a part of meeting stringent TAT targets on the delivery side, the retailer expected the warehousing & fulfilment partner to manage its last mile deliveries to its numerous stores on a daily basis. ", "Looking at Prozo\u2019s full-stack services and our ability to understand their supply chain better than other competitors, Prozo was entrusted with 100% outsourcing of their mother warehouse, regional fulfilment center and transportation requirements. Pro-WMS was deployed on the premises and was integrated with SAP. "]
}, {
    "image": "Enabling-1-2-days-deliveries-Pan-India-in-a-fast-scale-environment!.png",
    "categories": ["Healthcare"],
    "title": "Enabling 1-2 days deliveries Pan-India in a fast-scale environment!",
    "tags": "B2C, B2B online, D2C, FTL, Regional Warehouses, Control Tower, Command Centre",
    "description": ["A leading fitness-technology arm of a new-age healthcare company was looking for an agile but enterprise-grade supply chain partner who could meet their fast-evolving and ever-growing supply chain requirements. The brand had an aggressive target of delivering its products to  its customers pan-India in 1-2 days. The brand\u2019s existing growth partner was not able to meet their geographical expansion requirements and  stringent SLAs as it was not tech-enabled.", "Prozo was chosen as their supply chain partner looking at Prozo\u2019s vast fulfilment network of 30 multi-channel warehouses located across the country, its control tower and command center. Also, Prozo was able to offer the brand pay-per-use commercials. Not only that, Prozo offered the brand a \u201csingle rate card\u2019 for services pan-India. The brand is currently operating from seven fulfilment centers of Prozo. "]
}, {
    "image": "Capability-consolidation-and-end-to-end-fulfillment-for-a-group-of-brands!.jpg",
    "categories": ["FMCG"],
    "title": "Capability consolidation and end-to-end fulfilment for a group of brands!",
    "tags": "D2C, FMCG, ProWMS, ProOMS, ProShip, SAP Integration,  Primary Transportation (FTL), Mother, Regional Warehouses, Control Tower, Command Centre",
    "description": ["A D2C consumer vertical of a large Indian Conglomerate was looking to bring end-to-end warehousing & fulfilment of its five different consumer brands under one roof while enabling faster fulfilment for these brands. These five different consumer brands were operating from various locations across the country, typically from stand-alone facilities, and last mile delivery TATs were not consistent. Also, replenishment was very complex and inconsistent. ", "Prozo was chosen as their supply chain partner, looking at Prozo\u2019s enterprise-grade capabilities on three key parameters; PWLP (Prozo warehousing & logistics platform), PLN (Prozo\u2019s Logistics Network) and PFN (Prozo\u2019s fulfilment Network). ", "Currently, five brands of the conglomerate are operating from 4 regional warehouses of Prozo in East, West, North and South. Both primary and secondary transportation requirements of the brands are also handled by Prozo. These brands are leveraging Prozo\u2019s Warehouse Management System (WMS), Order Management System (OMS) and Shipping cum transport management Platform (Proship). The pan-India operations of the brand are managed through Control Tower & Command Centre. "]
}, {
    "image": "Pay-per-use,-scale-ready-supply-chain-for-one-of-the-Thrasios-of-India!.jpg",
    "categories": ["Apparel"],
    "title": "Pay-per-use, scale-ready supply chain for one of the Thrasios of India!",
    "tags": "Prozo Retail, B2C, B2B Online, Various categories, ProWMS, ProOMS, Regional Warehouses, Control Tower, Command Centre",
    "description": ["One of the Thrasios of India was looking for a warehousing partner who could enable fast-fulfilment for its acquired brands through a pan-India fulfilment network. The brand also wanted the warehousing partner\u2019s warehouses to be \u201c Amazon-prime\u201d & \u201cFlipkart-plus\u201d enabled so that their goods get fast-fulfilment badges on day 0. ", "Moreover, since this company was acquiring early stage companies in various categories, it was critical that the warehousing partner has strong capabilities across segments and categories; be it apparel, gym & fitness, Home & Outdoor or other categories. The brand was also looking for an online reseller to help resell on these platforms since the brand couldn\u2019t do it directly because of FDI restrictions. ", "Prozo was chosen as its warehousing, technology and retail partner looking at Prozo\u2019s end-to-end capabilities. The brand is operating from four of Prozo\u2019s warehouses and Prozo is entrusted with managing three of their brand\u2019s fulfilment and online retail. "]
}, {
    "image": "Fast-fulfillment-for-a-prescription-based-health-care-brand!.jpg",
    "categories": ["Healthcare"],
    "title": "Fast-fulfilment for a prescription-based health care brand!",
    "description": ["A leading hair-care brand was looking for a new-age supply chain partner with deep tech and fulfilment capabilities. They wanted a customized prescription-based flow of an order journey built into the WMS so that they can continue to be best-in-class in terms of fast-fulfilment despite every order needing a doctor's prescription before fulfilment.", "Prozo was chosen because of its in-house technology product and ability to turn around a custom integration to achieve the brand's vision of fast-fulfilment in a unique business scenario."]
}, {
    "image": "Upgradation of the supply chain by an India arm of an MNC Publisher!.jpg",
    "categories": ["Books"],
    "title": "Upgradation of the supply chain by an India arm of an MNC Publisher!",
    "tags": "B2B, Books, Retailer & Distributor Fulfilment, ProWMS, ProShip, Control Tower",
    "description": ["The Indian Arm of a global MNC book publisher was looking for a professional warehousing & fulfilment company which could handle both their warehousing & transportation requirements. Also, they wanted to move away from a single warehousing fulfilment strategy (CFA based) and have at least two fulfilment centers in North and South India to serve their booksellers and distributors. The brand was only operating on an ERP so far and hadn\u2019t adopted a WMS and TMS yet. ", "Prozo was chosen for its quality of warehousing infrastructure and ability to offer WMS and TMS that seamlessly integrated with their ERP. Moreover, Prozo\u2019s ability to enable automation of tracking & TAT reporting of B2B PTL shipments was a delight to them. ", "Currently, the brand operates from two locations and Prozo is entrusted with 100% of their warehousing & transportation business. "]
}, {
    "image": "On-demand-warehousing-to-enable-geographical-entry-of-a-large-FMCD-Client.jpg",
    "categories": ["FMCD"],
    "title": "On-demand warehousing to enable geographical entry of a large FMCD Client.",
    "tags": "On-demand warehousing, B2B, Pallet-in, pallet-out ops, ProWMS",
    "description": ["A large Indian Conglomerate\u2019s FMCD business vertical was looking to expand their footprint in Telangana but was unsure of the demand pickup for this vertical. Therefore, they were looking for on-demand warehousing at a pallet-level.", "Prozo offered one of its enterprise grade fulfilment centers in Hyderabad regions for pallet-in, pallet-out ops to this client thus enabling a seamless entry into that geography. Currently, Prozo is handling 6000+ pallet operations for this client in this region."]
}, {
    "image": "New-age-supply-chain-for-new-age-customers-of-a-b2c-platform\u2019s-private-label-arm!.jpg",
    "categories": ["Apparel"],
    "title": "New-age supply chain for new-age customers of a b2c platform\u2019s private label arm!",
    "tags": "Marketplaces as a customer, B2C, D2C, Control Tower, Command Centre",
    "description": ["A leading B2C marketplace was looking to leverage on a new-age supply chain company to build an efficient, pay-per-use, fast-fulfilment enabled supply chain.", "Prior to Prozo, they were managing drop-ship operations from the manufacturer\u2019s warehouse and another 3PL company. The company was facing key challenges in managing forward & return order processing TATs, inventory accuracy, and was struggling to add newer geographies as their private labels grew. Moreover, there was no real-time view into the supply chain and the visibility was dependent on manual interventions. ", "Prozo was chosen as their supply chain partner because of Prozo\u2019s Grade-A fulfilment centers, control tower & command center, single rate card for pan-India fulfilment, enterprise-grade capabilities and proven track record. "]
}, {
    "image": "London-based-apparel-brand-looking-to-expand-regionally-to-enable-fast-fulfillment-for-its-b2b-and-b2c-customers..jpg",
    "categories": ["Apparel"],
    "title": "London-based apparel brand looking to expand regionally to enable fast-fulfilment for its b2b and b2c customers.",
    "tags": "Apparel, Regional Fulfilment, Control Tower & Common Centre",
    "description": ["A leading apparel brand was looking to grow its D2C and B2C business while maintaining a single pool of inventory. They were looking for partners who could easily integrate with their existing technology ecosystem and have multi-channel fulfilment expertise. ", "Prozo was chosen as a supply chain partner because of its plug & play tech stack, HHT-enabled, multi-channel operations capabilities. Also, Prozo\u2019s ability to offer a pay-per-use commercial contract made perfect sense for the brand. "]
}, {
    "image": "agriculture.jpg",
    "categories": ["Agriculture"],
    "title": "Local fulfilment for a smart farming company!",
    "tags": "D2C, Fulfilment, Freight, Supply chain visibility, remote delivery",
    "description": ["A smart farming solution company working directly with farmers, was looking for multi-point small orders fulfilment in addition to the central warehouse.", "The company was facing challenges in shipping the products to the farmers from their offline retailers\u00ad and wanted a unified solution which could cater to larger geography. Prior information of delivery and accordingly availability of customers becomes a key point as far as remote deliveries are concerned.", "Prozo solved the delivery in remote areas with increased successful delivery attempts through real time view into the supply chain and enabled the brand to cater to the whole of India with its cost optimized freight network."]
}, {
    "image": "Regional Fulfillment Excellence for a leading FMCD Client!.jpg",
    "categories": ["FMCD"],
    "title": "Tech enabled warehousing & enhanced inventory visibility for a home appliances Manufacturer",
    "tags": "FIFO, Reporting, Intelligence, Warehousing, Inventory, order processing",
    "description": ["One of the leading home appliances manufacturers was looking for a tech enabled warehousing solution, which not only helps them fulfill hundreds of orders a day but also provides smart measures around inventory movement and aging. The brand\u2019s existing manual solution was not able to provide any info on aging and was a bottleneck in catering to volume of orders.", "Prozo was chosen as a supply chain partner owing to the comprehensive reporting and intelligence provided around the order processing and inventory. Prozo offered a complete scan-based solution omitting human errors and provided insights on slow- and fast-moving inventory. The system with FIFO in place ensures smooth inventory movement."]
}, {
    "image": "Integrated-order-management-and-differential-fulfillment-rules-for-a-natural-human-and-pet-product-company.png",
    "categories": ["Pet Care"],
    "title": "Integrated order management and differential fulfilment rules for a natural human and pet product company",
    "tags": "Batching, Hyperlocal, Distributors, shelf life, integration, B2B, pet products",
    "description": ["A young, human and pet food products company working with different traditional distributors, marketplaces and hyperlocal players wanted a unified order management at the warehousing and fulfilment stage. They were looking for a supply chain partner who could integrate with their ERP leading to reduction in processing time for inwards from suppliers and also at the time of processing B2B orders. Different customers had different shelf life cut off requirements for perishable products.", "Prozo was chosen as their warehousing and fulfilment partner looking at the seamless integration capability and tech enabled order processing, incorporating different customer needs and accordingly fulfilling orders. Prozo could provide them real time visibility on items specific batch details in a particular order."]
}, {
    "image": "Multi-channel-fulfillment-for-a-domestic-distributor-of-high-end-eyewear-brands.png",
    "categories": ["Lifestyle"],
    "title": "Multi-channel fulfilment for a domestic distributor of high end eyewear brands",
    "tags": "ProWMS, Prozo Retail, multi-channel fulfilment, B2B and B2C Freight, eyewear, sunglasses",
    "description": ["An Indian distributor of eyewear brands was looking for an integrated, tech enabled supply chain partner to handle 100% of its warehousing, fulfilment and tech needs for its B2B and  B2C business, Furthermore, they wanted support with making the same pool of inventory live across all leading online marketplaces for fast fulfilment.", "The brand chose Prozo because of our ability to handle B2B and B2C Warehousing and Freight Operations, along with an integrated tech stack that could enable real time visibility to SLAs and TATs. Also, Prozo had the ability to expose a common pool of inventory to both the customer\u2019s b2b brands and online marketplaces, along with fast fulfilment badges."]
}, {
    "image": "Professionalization of Supply Chain of a fast growing Intimate Hygiene D2C Brand.png",
    "categories": ["Healthcare"],
    "title": "Professionalization of Supply Chain of a fast growing Intimate Hygiene D2C Brand",
    "tags": "Value Added Services, ProShip, Warehousing, Fulfilment, D2C, Freight, Personal Care, Hygiene",
    "description": ["The brand was initially managing the warehousing & freight operations internally, and were looking for a specialized player to handle their supply chain as they scaled their business.", "Also, given the brand retails intimate hygiene products, they wanted a partner who could provide value added services w.r.t to packaging and ensure all products are packed in a very hygienic and safe environment.", "The brand decided to work with Prozo because of its integrated supply chain offerings - tech enabled Warehousing & Freight operations, along with ProShip - Prozo\u2019s freight recommendation engine."]
}, {
    "image": "Pan-India-D2C-Warehousing-for-a-handbags-and-accessories-brand.png",
    "categories": ["Lifestyle"],
    "title": "Pan-India D2C Warehousing for a handbags and accessories brand",
    "tags": "Value Added Services, ProShip, Warehousing, Fulfilment, D2C, Freight, Personal Care, Hygiene",
    "description": ["The brand was a dominant B2B player, and wanted to scale up its D2C business. They were looking for a partner who understands B2C and D2C warehousing & fulfilment operations and could provide best in class SLAs and TATs.", "The brand started D2C operations from 1 location. Based on Prozo\u2019s tech enabled services and robust client management, the brand decided to scale up its warehousing footprint pan-India with Prozo. Currently, Prozo is serving the brand across 4 different locations in North, South, East and West to enable fast fulfilment for its customers"]
}, {
    "image": "Integrated-Warehousing-&-Freight-Services-for-a-fast-growing-D2C-athleisure-and-sports-merchandise-brand.png",
    "categories": ["Healthcare"],
    "title": "Integrated Warehousing & Freight Services for a fast growing D2C athleisure and sports merchandise brand",
    "tags": "ProShip, Warehousing, Fast Fulfilment, Control Tower, Command Center, Freight, B2C and D2C, Merchandise, Apparel",
    "description": ["A leading D2C athleisure and sports merchandise brands was evaluating multiple service partners who could offer best in class, tech enabled warehousing & last mile freight solutions and handle its BAU and peak operations, with peak demand being 10X of BAU. The brand\u2019s existing service partner wasn\u2019t able to handle such peak volumes while maintaining Service Levels", "The brand chose Prozo because of our ability to understand its operations and pain-points and onboard it quickly before a major sporting event, when order volume goes up 10X.", "The brand leveraged Prozo\u2019s intelligent inventory placement platform, ProPlanning, to optimize stocking points, leading to faster shipment timelines with lower last mile delivery costs.", "Prozo started with offering Warehousing & fulfilment at 1 location to the brand, and soon scaled up to 3 locations, along with last mile freight services via ProShip, Prozo\u2019s freight recommendation and allocation engine."]
}, {
    "image": "Multi-channel-fulfillment-for-a-women\u2019s-wellness-brand.png",
    "categories": ["Healthcare"],
    "title": "Multi-channel fulfilment for a women\u2019s wellness brand",
    "tags": "Multi Channel Fulfilment, B2B, B2C, D2C, Warehousing, Control Tower, Command Center, Hygiene, Personal care",
    "description": ["The brand wanted to partner with a new age, agile supply chain partner to handle its B2B, B2C and D2C warehousing & fulfilment requirements on a flexible commercial model. The brand also wanted the service partner to provide intensive Value Added Services like kitting and repackaging in a clean and hygienic environment.", "Prozo could meet the brand\u2019s requirements because of its deep experience in handling multi channel operations on a pay-per-use commercial model. Also, Prozo deployed trained manpower to handle the products in a safe and hygienic environment, while ensuring adherence to SLAs and TATs during BAU and peak sale events."]
}, {
    "image": "Pan-India,-Multi-channel-fulfillment-on-a-pay-per-use-model.png",
    "categories": ["Lifestyle"],
    "title": "Pan-India, Multi-channel fulfilment on a pay-per-use model",
    "tags": "Multi Channel Fulfilment, B2B, B2C, D2C, Warehousing, Control Tower, Command Center, Hardlines, Baby Care, ProWMS, ProShip, PTL",
    "description": ["A high growth baby gear (hardlines) brand wanted a tech enabled supply chain partner for its multi-channel warehousing,fulfilment and supply chain tech requirements.", "Prozo offered the brand a unified rate card and a Pan-India footprint. Given the products were large and bulky, Prozo was able to offer optimum stocking points, thus bringing down the brand\u2019s logistics cost by minimizing national deliveries and maximizing local and regional deliveries. Along with warehousing & freight, the brand leveraged on ProWMS and ProShip (Freight allocation and recommendation engine) to have a real time view of its operations.", "The pan-India, multi-channel operations of the brand are managed through Control Tower & Command Centre."]
}, {
    "image": "Large-Hospitality-Brand-looking-to-professionalize-its-supply-chain.png",
    "categories": ["Hospitality"],
    "title": "Large Hospitality Brand looking to professionalize its supply chain",
    "tags": "ProWMS, Control Tower, Command Center, Pan-India Fulfilment, PTL, Freight, Hospitality",
    "description": ["A fast growing hospitality brand was looking to work with a professional supply chain partner for the first time. The brand wanted a partner who could offer a Pan-India warehousing & freight network along with an integrated tech stack.", "The brand chose Prozo because of its in-house, scalable tech stack (ProWMS) and ability to efficiently manage Pan-India operations through a central Control Tower & Command Center. Prozo is currently serving the brand at 3 locations, along with handling its B2B freight operations."]
}]

export default caseStudies;