import { Link } from "gatsby";
import React, { useRef } from "react"
import ReCAPTCHA from "react-google-recaptcha";

function ContactForm({ hideHeading = false, title = 'Optimise Your Supply Chain' }) {

    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [formStatus, setFormStatus] = React.useState(null);
    const captchaRef = useRef(null)
    const timerRef = useRef();

    React.useEffect(() => {

        timerRef.current = setTimeout(() => {
            setFormStatus(null)
        }, [5000])

        return () => clearTimeout(timerRef.current)

    }, [JSON.stringify(formStatus)])

    const onSubmit = async (e) => {
        e.preventDefault();

        setIsSubmitting(true)

        const formData = new FormData(e.target);
        const formDataJson = Object.fromEntries(formData)

        if (typeof window !== 'undefined') {
            formDataJson.url = window.location.href
        }


        try {

            const headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('x-api-key', 'RMeXFA72dv9jc8f8A7Omw9LJ8waoKK6OaMhXVfhQ');

            const request = await fetch("https://j2y9v45ca8.execute-api.ap-south-1.amazonaws.com/prod/contact", {
                body: JSON.stringify(formDataJson),
                method: 'POST',
                headers,
                redirect: 'follow',
                mode: 'cors'
            })

            if (!request.ok) {
                throw new Error()
            }


            e.target.reset()
            setFormStatus({ status: 'success', message: 'Successfully Submitted!' })

        } catch (err) {
            setFormStatus({ status: 'danger', message: 'Oops! Something went wrong, Please try again later.' })

        } finally {
            captchaRef.current.reset();
            setIsSubmitting(false)
        }

    }

    return (
        <React.Fragment>
            <div className="section-title contactus" hidden={hideHeading}>
                <h3 className="font-weight-bold">{title}</h3>
                <p className="subtitle">Choose Prozo as your supply chain partner and experience the difference that operational excellence, technological integration, and a client-centric approach can make. Let us help you unlock the full potential of your supply chain and drive your business forward.</p>
            </div>
            <div className="content pt-2">
                <form className="leadForm" onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-lg-4 col-12">
                            <div className="form-group position-relative">
                                <label>Name*</label>
                                <i className="picon mdi mdi-account-check text-dark" />
                                <input type="text" className="form-control pl-5 bg-transparent" id="name" placeholder="Name" name="name" required="required" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-12">
                            <div className="form-group position-relative">
                                <label>Phone Number*</label>
                                <i className="picon mdi mdi-phone text-dark" />
                                <input type="text" className="form-control pl-5 bg-transparent" id="phone" placeholder="Phone Number" name="phone" required="required" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-12">
                            <div className="form-group position-relative">
                                <label>Company Name*</label>
                                <i className="mdi mdi-contacts picon text-dark" />
                                <input type="text" className="form-control pl-5 bg-transparent" id="cname" placeholder="Company Name" name="company" required="required" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-12">
                            <div className="form-group position-relative">
                                <label>Business Email*</label>
                                <i className="mdi mdi-email picon text-dark" />
                                <input type="email" className="form-control pl-5 bg-transparent" id="email" placeholder="Business Email Address" name="email" required="required" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-12">
                            <div className="form-group position-relative">
                                <label>Product Category*</label>
                                <i className="mdi mdi-collage picon text-dark" />
                                <input type="text" className="form-control pl-5 bg-transparent" id="pcategory" placeholder="Eg Apparel, Footwear, Grocery etc." name="productCategory" />
                            </div>
                        </div>

                        <div className="col-lg-12 col-12" style={{ maxWidth: '100%', overflow: 'hidden' }}>
                            <div className="form-group position-relative">
                                <ReCAPTCHA ref={captchaRef} sitekey="6Lc8z28gAAAAAD4lwKtpnNu5sKK8NnAhxRVQ0MzJ" />
                            </div>
                        </div>

                        <div className="col-lg-12 col-12 mt-2">
                            <p className="consent">
                                <small style={{ fontSize: 13 }}>Your information will be processed according to our Terms of use and <Link to="/privacy-policy"> <u>Privacy Policy</u> </Link>. By clicking below, you authorize Prozo to contact and send you marketing communications. You may unsubscribe from these communications anytime.</small>
                            </p>
                        </div>
                        {formStatus && (
                            <div className="col-lg-12 mt-1">
                                <div className={`alert alert-${formStatus?.status} alert-dismissible fade show`} role="alert">
                                    {formStatus?.message}
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                        )}

                        <div className="col-lg-12 mt-1">
                            <button disabled={isSubmitting} type="submit" className="btn btn-primary pulse-blue">
                                {isSubmitting ? "Submitting..." : "Submit"} <i data-feather="arrow-right" className="icons" />
                            </button>

                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}

export default ContactForm;