exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-case-study-jsx": () => import("./../../../src/pages/about-us/case-study.jsx" /* webpackChunkName: "component---src-pages-about-us-case-study-jsx" */),
  "component---src-pages-about-us-contact-jsx": () => import("./../../../src/pages/about-us/contact.jsx" /* webpackChunkName: "component---src-pages-about-us-contact-jsx" */),
  "component---src-pages-about-us-our-clients-jsx": () => import("./../../../src/pages/about-us/our-clients.jsx" /* webpackChunkName: "component---src-pages-about-us-our-clients-jsx" */),
  "component---src-pages-about-us-pfn-jsx": () => import("./../../../src/pages/about-us/pfn.jsx" /* webpackChunkName: "component---src-pages-about-us-pfn-jsx" */),
  "component---src-pages-fulfilment-services-jsx": () => import("./../../../src/pages/fulfilment-services.jsx" /* webpackChunkName: "component---src-pages-fulfilment-services-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-media-jsx": () => import("./../../../src/pages/media.jsx" /* webpackChunkName: "component---src-pages-media-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-refund-policy-jsx": () => import("./../../../src/pages/refund-policy.jsx" /* webpackChunkName: "component---src-pages-refund-policy-jsx" */),
  "component---src-pages-shipping-d-2-c-freight-jsx": () => import("./../../../src/pages/shipping/d2c-freight.jsx" /* webpackChunkName: "component---src-pages-shipping-d-2-c-freight-jsx" */),
  "component---src-pages-shipping-express-freight-jsx": () => import("./../../../src/pages/shipping/express-freight.jsx" /* webpackChunkName: "component---src-pages-shipping-express-freight-jsx" */),
  "component---src-pages-shipping-post-shipping-solution-jsx": () => import("./../../../src/pages/shipping/post-shipping-solution.jsx" /* webpackChunkName: "component---src-pages-shipping-post-shipping-solution-jsx" */),
  "component---src-pages-technology-control-tower-jsx": () => import("./../../../src/pages/technology/control-tower.jsx" /* webpackChunkName: "component---src-pages-technology-control-tower-jsx" */),
  "component---src-pages-technology-express-proship-jsx": () => import("./../../../src/pages/technology/express-proship.jsx" /* webpackChunkName: "component---src-pages-technology-express-proship-jsx" */),
  "component---src-pages-technology-freight-management-system-jsx": () => import("./../../../src/pages/technology/freight-management-system.jsx" /* webpackChunkName: "component---src-pages-technology-freight-management-system-jsx" */),
  "component---src-pages-technology-integrated-supply-chain-jsx": () => import("./../../../src/pages/technology/integrated-supply-chain.jsx" /* webpackChunkName: "component---src-pages-technology-integrated-supply-chain-jsx" */),
  "component---src-pages-technology-order-management-system-jsx": () => import("./../../../src/pages/technology/order-management-system.jsx" /* webpackChunkName: "component---src-pages-technology-order-management-system-jsx" */),
  "component---src-pages-technology-propilot-jsx": () => import("./../../../src/pages/technology/propilot.jsx" /* webpackChunkName: "component---src-pages-technology-propilot-jsx" */),
  "component---src-pages-technology-warehouse-management-system-jsx": () => import("./../../../src/pages/technology/warehouse-management-system.jsx" /* webpackChunkName: "component---src-pages-technology-warehouse-management-system-jsx" */),
  "component---src-pages-toc-jsx": () => import("./../../../src/pages/toc.jsx" /* webpackChunkName: "component---src-pages-toc-jsx" */),
  "component---src-pages-warehouse-and-fulfillment-jsx": () => import("./../../../src/pages/warehouse-and-fulfillment.jsx" /* webpackChunkName: "component---src-pages-warehouse-and-fulfillment-jsx" */),
  "component---src-pages-warehouse-fulfillment-bengaluru-ga-jsx": () => import("./../../../src/pages/warehouse-fulfillment-bengaluru-ga.jsx" /* webpackChunkName: "component---src-pages-warehouse-fulfillment-bengaluru-ga-jsx" */),
  "component---src-pages-warehouse-fulfillment-chennai-ga-jsx": () => import("./../../../src/pages/warehouse-fulfillment-chennai-ga.jsx" /* webpackChunkName: "component---src-pages-warehouse-fulfillment-chennai-ga-jsx" */),
  "component---src-pages-warehouse-fulfillment-delhi-ga-jsx": () => import("./../../../src/pages/warehouse-fulfillment-delhi-ga.jsx" /* webpackChunkName: "component---src-pages-warehouse-fulfillment-delhi-ga-jsx" */),
  "component---src-pages-warehouse-fulfillment-gurugram-ga-jsx": () => import("./../../../src/pages/warehouse-fulfillment-gurugram-ga.jsx" /* webpackChunkName: "component---src-pages-warehouse-fulfillment-gurugram-ga-jsx" */),
  "component---src-pages-warehouse-fulfillment-hyderabad-ga-jsx": () => import("./../../../src/pages/warehouse-fulfillment-hyderabad-ga.jsx" /* webpackChunkName: "component---src-pages-warehouse-fulfillment-hyderabad-ga-jsx" */),
  "component---src-pages-warehouse-fulfillment-kolkata-ga-jsx": () => import("./../../../src/pages/warehouse-fulfillment-kolkata-ga.jsx" /* webpackChunkName: "component---src-pages-warehouse-fulfillment-kolkata-ga-jsx" */),
  "component---src-pages-warehouse-fulfillment-lucknow-ga-jsx": () => import("./../../../src/pages/warehouse-fulfillment-lucknow-ga.jsx" /* webpackChunkName: "component---src-pages-warehouse-fulfillment-lucknow-ga-jsx" */),
  "component---src-pages-warehouse-fulfillment-mumbai-ga-jsx": () => import("./../../../src/pages/warehouse-fulfillment-mumbai-ga.jsx" /* webpackChunkName: "component---src-pages-warehouse-fulfillment-mumbai-ga-jsx" */),
  "component---src-pages-warehouse-fulfillment-noida-ga-jsx": () => import("./../../../src/pages/warehouse-fulfillment-noida-ga.jsx" /* webpackChunkName: "component---src-pages-warehouse-fulfillment-noida-ga-jsx" */)
}

