const deliveryOptions = [
    {
        title: 'Surface & Air',
        content: [
            'Choose between surface and air delivery options based on your needs for speed and cost-efficiency. Our network ensures your parcels are handled with care, arriving on time, every time.'
        ]
    },
    {
        title: 'Same City SDD & NDD',
        content: [
            'Same-Day Delivery (SDD): Get your parcel delivered to your customers within hours with our same-city same-day delivery service',
            'Next-Day Delivery (NDD): Our next-day delivery service within the same city ensures your parcels arrive promptly the following day.'
        ]
    },
    {
        title: 'Metro to Metro NDD',
        content: [
            'Benefit from our efficient next-day delivery service between metro cities. This option is ideal for brands looking to ship parcels across major urban centers quickly and reliably.',
        ]
    },
    {
        title: 'Hyperlocal',
        content: [
            'Perfect for businesses needing rapid fulfilment within small geographic areas, this service ensures your customers get their orders in record time.',
        ]
    },
    {
        title: 'Express',
        content: [
            'Prozo’s Express (PTL) services offer cost-efficient, flexible partial truckload shipping solutions, ensuring timely, secure deliveries with optimized routes and real-time tracking for diverse cargo needs.',
        ]
    }
];

export default deliveryOptions;