const shippingSolution = [
    {
        title: 'Courier Partners',
        content: [
            'We partner with leading courier services to provide you with the best shipping options, ensuring timely and efficient delivery.'
        ]
    },
    {
        title: 'Order Management System',
        content: [
            'Integrate your order management systems with Prozo to streamline your operations.',
        ]
    },
    {
        title: 'Webstores',
        content: [
            'Our platform supports integration with a wide range of eCommerce platforms, ensuring your online store operations are synchronized with your supply chain.',
        ]
    },
    {
        title: 'Post-Shipping tools',
        content: [
            'Enhance your post-shipping process with integrations designed to manage returns, customer support, and more.',
        ]
    }
];

export default shippingSolution;