import React from "react";

function Video({ src, ...rest }) {

    const urlType = checkURL(src);

    if (urlType === 'video') {
        return (
            <video muted autoPlay controls loop {...rest}>
                <source src={src} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        )
    }

    if (urlType === 'image') {
        return <img src={src} alt="Prozo" {...rest} />
    }

    return null;
}

function checkURL(url) {
    // Define arrays of common image and video extensions
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];
    const videoExtensions = ['mp4', 'mkv', 'webm', 'avi', 'mov', 'wmv', 'flv'];

    // Extract the file extension from the URL
    const extension = url.split('.').pop().toLowerCase();

    // Check if the extension is in the list of image extensions
    if (imageExtensions.includes(extension)) {
        return 'image';
    }

    // Check if the extension is in the list of video extensions
    if (videoExtensions.includes(extension)) {
        return 'video';
    }

    // If it doesn't match any of the known extensions
    return 'unknown';
}

export default Video;