const categories = [
    { name: 'Apparel', image: 'images/new/apparel.jpg' },
    { name: 'Books', image: 'images/new/books.jpg' },
    { name: 'Cosmetics', image: 'images/new/Cosmetics.jpg' },
    { name: 'FMCD', image: 'images/new/FMCD.jpg' },
    { name: 'FMCG', image: 'images/new/FMCG.png' },


    { name: 'Healthcare', image: 'images/new/Health-Car.jpg' },
    { name: 'Lifestyle', image: 'images/new/LifestylePepSafilo.jpg' },
    { name: 'Retail', image: 'images/new/retail.jpg' },
    { name: 'Spares', image: 'images/new/Spares---Hyundai.jpg' },
    { name: 'Toys', image: 'images/new/toys.jpg' },


    { name: 'Pet Care', image: 'images/new/pet-care.png' },
    { name: 'Agriculture', image: 'images/new/agriculture.jpg' },
    { name: 'Hospitality', image: 'images/new/hospitality.jpg' },
    { name: 'Cold Storage', image: 'images/new/Cold Storage.png' },
]

export default categories;