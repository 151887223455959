import React from "react";

//import "./static/css/style.css";
import "./static/css/tree.css";
import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.css";

import AppLayout from "./src/components/layouts/AppLayout";

export const wrapPageElement = ({ element, props }) => {
  return (<AppLayout {...props}>{element}</AppLayout>)
}

export const onRouteUpdate = ({ location }) => {

  if (location.hash) {
    const elementID = location.hash.replace("#", "");
    const element = document.getElementById(elementID);

    const allActiveElements = document.querySelectorAll(".navigation-active");
    for (const ele of allActiveElements) {
      ele.classList.remove('navigation-active');
    }
    
    if (element) {
      if (elementID.includes('INNER__')) {
        element.classList.add("navigation-active");
      }
    }
  }
};