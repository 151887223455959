import React from "react";

import Header from "../Header";
import Footer from "../Footer";

import { Helmet } from "react-helmet";


function AppLayout({ children }) {

    return (
        <React.Fragment>

            <Helmet>
                <meta name="icon" href="/images/favicon/png" />
                <script type="text/javascript" src="/js/jquery.easing.min.js" key="jquery.easing.min"></script>
                <script type="text/javascript" src="/js/scrollspy.min.js" key="scrollspy.min"></script>
                <script type="text/javascript" src="/js/app.js" key="app-js"></script>
            </Helmet>

            <Header />
            {children}
            <Footer addClass="bg-gray" />
        </React.Fragment>
    )

}

export default AppLayout;